define("discourse/plugins/discourse-epic-games/discourse/connectors/composer-after-composer-editor/legal-notice", ["exports", "@glimmer/component", "discourse-common/helpers/i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _i18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class LegalNotice extends _component.default {
    static shouldRender(_, _ref) {
      let {
        siteSettings
      } = _ref;
      return siteSettings.epic_legal_notice_enabled;
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="unreal-composer-legal-notice">
          <p>
            {{i18n "discourse_epic_games.legal_notice.content"}}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={{i18n "discourse_epic_games.legal_notice.link.url"}}
            >
              {{i18n "discourse_epic_games.legal_notice.link.text"}}
            </a>
          </p>
        </div>
      
    */
    {
      "id": "yKZoC3s4",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"unreal-composer-legal-notice\"],[12],[1,\"\\n      \"],[10,2],[12],[1,\"\\n        \"],[1,[28,[32,0],[\"discourse_epic_games.legal_notice.content\"],null]],[1,\"\\n        \"],[10,3],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[15,6,[28,[32,0],[\"discourse_epic_games.legal_notice.link.url\"],null]],[12],[1,\"\\n          \"],[1,[28,[32,0],[\"discourse_epic_games.legal_notice.link.text\"],null]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-epic-games/discourse/connectors/composer-after-composer-editor/legal-notice.js",
      "scope": () => [_i18n.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = LegalNotice;
});