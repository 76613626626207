define("discourse/plugins/discourse-epic-games/discourse/components/advanced-search-link", ["exports", "@glimmer/component", "@ember/service", "discourse-common/helpers/i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _i18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdvancedSearchLink extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "search", [_service.service]))();
    #search = (() => (dt7948.i(this, "search"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    get searchUrl() {
      let url = "/search";
      // if the user has entered a search term, we want to pass that along to the advanced search page
      // including the tag filter
      if (this.search.activeGlobalSearchTerm.length) {
        url = `${url}?q=${encodeURIComponent(this.search.activeGlobalSearchTerm)} tag:${this.site.globalFilter}`;
      }
      return url;
    }
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="epic-advanced-search-link">
          <a href={{this.searchUrl}}>
            {{i18n "discourse_epic_games.search_menu.advanced_search"}}
          </a>
        </div>
      
    */
    {
      "id": "PE04R+Y6",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"epic-advanced-search-link\"],[12],[1,\"\\n      \"],[10,3],[15,6,[30,0,[\"searchUrl\"]]],[12],[1,\"\\n        \"],[1,[28,[32,0],[\"discourse_epic_games.search_menu.advanced_search\"],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-epic-games/discourse/components/advanced-search-link.js",
      "scope": () => [_i18n.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AdvancedSearchLink;
});