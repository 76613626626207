define("discourse/plugins/discourse-epic-games/discourse/services/epic-filtered-categories", ["exports", "@glimmer/tracking", "@ember/service", "discourse/models/category-list"], function (_exports, _tracking, _service, _categoryList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class EpicFilteredCategoriesService extends _service.default {
    static #_ = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "store", [_service.service]))();
    #store = (() => (dt7948.i(this, "store"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "categories", [_tracking.tracked], function () {
      return [];
    }))();
    #categories = (() => (dt7948.i(this, "categories"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "subcategories", [_tracking.tracked], function () {
      return [];
    }))();
    #subcategories = (() => (dt7948.i(this, "subcategories"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "globalFilter", [_tracking.tracked], function () {
      return "";
    }))();
    #globalFilter = (() => (dt7948.i(this, "globalFilter"), void 0))();
    async loadCategories(globalFilter) {
      // ignore calls if the globalFilter matches the last call performed even if the server still did not provide the
      // results. This prevents the scenario where multiple requests are made in quick succession before the first one
      // returns.
      if (this.globalFilter === globalFilter) {
        // prevent multiple hits for the same global filter
        return;
      }
      this.globalFilter = globalFilter;
      const categoryList = await _categoryList.default.list(this.store);
      this.categories = categoryList.categories;
    }
    updateCategories(categoryList) {
      this.globalFilter = categoryList.globalFilter;
      this.categories = categoryList.categories;
      if (categoryList.categories?.content) {
        this.subcategories = categoryList.categories.content.flatMap(category => category.subcategories || []);
      }
    }
  }
  _exports.default = EpicFilteredCategoriesService;
});