define("discourse/plugins/discourse-epic-games/discourse/components/nav-link-dropdown", ["exports", "@ember/object", "@ember/service", "discourse/models/nav-item", "select-kit/components/combo-box"], function (_exports, _object, _service, _navItem, _comboBox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _comboBox.default.extend(dt7948.p({
    router: (0, _service.service)(),
    siteSettings: (0, _service.service)(),
    topicTrackingState: (0, _service.service)(),
    classNames: ["nav-link-dropdown"],
    pluginApiIdentifiers: ["nav-link-dropdown"],
    nameProperty: "displayName",
    selectKitOptions: {
      icon: "ellipsis-vertical",
      caretDownIcon: null,
      caretUpIcon: null
    },
    get content() {
      const allowedNavItems = ["new", "unread", "top"];
      const currentRouteQueryParams = this.router.currentRoute.queryParams;
      const tagId = this.topicTrackingState.filterTag;
      const siteSettings = this.siteSettings;
      const navItems = _navItem.default.buildList(this.category, {
        currentRouteQueryParams,
        tagId,
        siteSettings
      }).filter(item => allowedNavItems.includes(item.name)).map((item, index) => {
        let href;
        const currentTag = item.tagId ? item.tagId : item.currentRouteQueryParams.tag;
        if (item.name === "new") {
          href = `/tag/${currentTag}/l/new`;
        } else if (item.name === "unread") {
          href = `/tag/${currentTag}/l/unread`;
        } else if (item.name === "top") {
          href = `/tag/${currentTag}/l/top`;
        } else {
          href = "#";
        }
        if (item.name === "unread" && item.count === 0) {
          return;
        }
        return {
          id: index,
          name: item.name,
          title: item.title,
          displayName: item.displayName,
          value: item.value,
          href
        };
      });
      return navItems;
    },
    modifyComponentForRow() {
      return "nav-link-dropdown-row";
    }
  }, [["method", "content", [(0, _object.computed)("category")]]]));
});