define("discourse/plugins/discourse-epic-games/discourse/components/bug-report-topic-status", ["exports", "@glimmer/component", "@ember/helper", "@ember/template", "discourse/helpers/concat-class", "discourse-common/helpers/d-icon", "discourse-common/helpers/i18n", "discourse/plugins/discourse-epic-games/discourse/lib/constants", "@ember/component", "@ember/template-factory"], function (_exports, _component, _helper, _template, _concatClass, _dIcon, _i18n, _constants, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class BugReportTopicStatus extends _component.default {
    get statusObject() {
      return _constants.BUG_REPORT_TOPIC_STATUSES[this.args.status];
    }
    get class() {
      return this.statusObject.class;
    }
    get status() {
      return (0, _i18n.default)(this.statusObject.status);
    }
    get title() {
      return (0, _i18n.default)(this.statusObject.title);
    }
    get message() {
      return (0, _i18n.default)(this.statusObject.message, {
        bug_report_jira_id: "[FORT-1234]",
        jira_status: "Fixed - CL #1234"
      });
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class={{concatClass "bug-report-topic-status" this.class}}>
          <div class="topic-status-wrapper">
            <div class="topic-status-top">
              <div class="topic-status-icon">
                {{icon "ue-home"}}
              </div>
              <div class="topic-status-content">
                <span class="topic-status-title">{{this.title}}</span>
                <span class="topic-status-description">
                  {{i18n "discourse_epic_games.bug_report_status.description"}}
                </span>
              </div>
            </div>
            <div class={{concat "topic-status-" @status}}>
              {{this.status}}
            </div>
          </div>
          <div class="topic-status-message">
            {{htmlSafe this.message}}
          </div>
        </div>
      
    */
    {
      "id": "PZOAkdc6",
      "block": "[[[1,\"\\n    \"],[10,0],[15,0,[28,[32,0],[\"bug-report-topic-status\",[30,0,[\"class\"]]],null]],[12],[1,\"\\n      \"],[10,0],[14,0,\"topic-status-wrapper\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"topic-status-top\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"topic-status-icon\"],[12],[1,\"\\n            \"],[1,[28,[32,1],[\"ue-home\"],null]],[1,\"\\n          \"],[13],[1,\"\\n          \"],[10,0],[14,0,\"topic-status-content\"],[12],[1,\"\\n            \"],[10,1],[14,0,\"topic-status-title\"],[12],[1,[30,0,[\"title\"]]],[13],[1,\"\\n            \"],[10,1],[14,0,\"topic-status-description\"],[12],[1,\"\\n              \"],[1,[28,[32,2],[\"discourse_epic_games.bug_report_status.description\"],null]],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[15,0,[28,[32,3],[\"topic-status-\",[30,1]],null]],[12],[1,\"\\n          \"],[1,[30,0,[\"status\"]]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"topic-status-message\"],[12],[1,\"\\n        \"],[1,[28,[32,4],[[30,0,[\"message\"]]],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@status\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-epic-games/discourse/components/bug-report-topic-status.js",
      "scope": () => [_concatClass.default, _dIcon.default, _i18n.default, _helper.concat, _template.htmlSafe],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = BugReportTopicStatus;
});