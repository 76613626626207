define("discourse/plugins/discourse-epic-games/discourse/raw-templates/connectors/topic-list-before-status/type-icon", ["exports", "discourse-common/lib/raw-handlebars", "discourse-common/lib/raw-templates"], function (_exports, _rawHandlebars, _rawTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let template = (0, _rawHandlebars.template)({
    "compiler": [8, ">= 4.3.0"],
    "main": function (container, depth0, helpers, partials, data) {
      var alias1 = depth0 != null ? depth0 : container.nullContext || {},
        alias2 = container.hooks.helperMissing,
        alias3 = container.escapeExpression,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return "<span class=\"custom-topic-type\">\n  " + alias3((lookupProperty(helpers, "d-icon") || depth0 && lookupProperty(depth0, "d-icon") || alias2).call(alias1, "gf-unreal-engine", {
        "name": "d-icon",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 2,
            "column": 2
          },
          "end": {
            "line": 2,
            "column": 31
          }
        }
      })) + "\n  " + alias3((lookupProperty(helpers, "d-icon") || depth0 && lookupProperty(depth0, "d-icon") || alias2).call(alias1, "gf-fortnite", {
        "name": "d-icon",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 3,
            "column": 2
          },
          "end": {
            "line": 3,
            "column": 26
          }
        }
      })) + "\n  " + alias3((lookupProperty(helpers, "d-icon") || depth0 && lookupProperty(depth0, "d-icon") || alias2).call(alias1, "gf-epic-games-store", {
        "name": "d-icon",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 4,
            "column": 2
          },
          "end": {
            "line": 4,
            "column": 34
          }
        }
      })) + "\n  " + alias3((lookupProperty(helpers, "d-icon") || depth0 && lookupProperty(depth0, "d-icon") || alias2).call(alias1, "gf-capturing-reality", {
        "name": "d-icon",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 5,
            "column": 2
          },
          "end": {
            "line": 5,
            "column": 35
          }
        }
      })) + "\n  " + alias3((lookupProperty(helpers, "d-icon") || depth0 && lookupProperty(depth0, "d-icon") || alias2).call(alias1, "ue-question", {
        "name": "d-icon",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 6,
            "column": 2
          },
          "end": {
            "line": 6,
            "column": 26
          }
        }
      })) + "\n  " + alias3((lookupProperty(helpers, "d-icon") || depth0 && lookupProperty(depth0, "d-icon") || alias2).call(alias1, "ue-discussion", {
        "name": "d-icon",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 7,
            "column": 2
          },
          "end": {
            "line": 7,
            "column": 28
          }
        }
      })) + "\n  " + alias3((lookupProperty(helpers, "d-icon") || depth0 && lookupProperty(depth0, "d-icon") || alias2).call(alias1, "gf-metahuman", {
        "name": "d-icon",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 8,
            "column": 2
          },
          "end": {
            "line": 8,
            "column": 27
          }
        }
      })) + "\n  " + alias3((lookupProperty(helpers, "d-icon") || depth0 && lookupProperty(depth0, "d-icon") || alias2).call(alias1, "gf-twinmotion", {
        "name": "d-icon",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 9,
            "column": 2
          },
          "end": {
            "line": 9,
            "column": 28
          }
        }
      })) + "\n  " + alias3((lookupProperty(helpers, "d-icon") || depth0 && lookupProperty(depth0, "d-icon") || alias2).call(alias1, "gf-fab", {
        "name": "d-icon",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 10,
            "column": 2
          },
          "end": {
            "line": 10,
            "column": 21
          }
        }
      })) + "\n  " + alias3((lookupProperty(helpers, "d-icon") || depth0 && lookupProperty(depth0, "d-icon") || alias2).call(alias1, "gf-epic-for-indies", {
        "name": "d-icon",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 11,
            "column": 2
          },
          "end": {
            "line": 11,
            "column": 33
          }
        }
      })) + "\n</span>\n";
    },
    "useData": true
  });
  (0, _rawTemplates.addRawTemplate)("javascripts/connectors/topic-list-before-status/type-icon", template, {
    core: false,
    pluginName: "discourse-epic-games",
    hasModernReplacement: false
  });
  var _default = _exports.default = template;
});