define("discourse/plugins/discourse-epic-games/discourse/connectors/topic-area-bottom/bug-report-topic-statuses", ["exports", "@glimmer/component", "discourse/plugins/discourse-epic-games/discourse/components/bug-report-topic-status", "discourse/plugins/discourse-epic-games/discourse/lib/bug-report-topic-status", "discourse/plugins/discourse-epic-games/discourse/lib/constants", "@ember/component", "@ember/template-factory"], function (_exports, _component, _bugReportTopicStatus, _bugReportTopicStatus2, _constants, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class BugReportTopicStatuses extends _component.default {
    static shouldRender(_ref, _ref2) {
      let {
        model
      } = _ref;
      let {
        siteSettings
      } = _ref2;
      return (0, _bugReportTopicStatus2.shouldRenderTopicStatus)({
        topic: model,
        siteSettings
      });
    }
    get status() {
      const matchingTag = this.args.outletArgs.model.get("tags").find(tag => {
        return Object.keys(_constants.BUG_REPORT_TOPIC_STATUSES).includes(tag);
      });
      return matchingTag;
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.status}}
          <BugReportTopicStatus @status={{this.status}} />
        {{/if}}
      
    */
    {
      "id": "ov2/SbJl",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"status\"]],[[[1,\"      \"],[8,[32,0],null,[[\"@status\"],[[30,0,[\"status\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-epic-games/discourse/connectors/topic-area-bottom/bug-report-topic-statuses.js",
      "scope": () => [_bugReportTopicStatus.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = BugReportTopicStatuses;
});