define("discourse/plugins/discourse-epic-games/discourse/initializers/add-parent-category-row-class", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "add-parent-category-row-class",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.14", api => {
        api.registerValueTransformer("parent-category-row-class", _ref => {
          let {
            value,
            context
          } = _ref;
          if (context.category.subcategory_list) {
            value.push("has-subcategories");
          }
          return value;
        });
      });
    }
  };
});