define("discourse/plugins/discourse-epic-games/discourse/templates/admin-plugins-filter-tags", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <FilterTagsEditor @filterTags={{this.model.filter_tags}} />
  <h3>{{i18n "discourse_epic_games.admin.category_matrix"}}</h3>
  <div class="filter-tag-admin-pane">
    <CategoryMatrix
      @filterTags={{this.model.filter_tags}}
      @setCategoryIdsForTag={{this.setCategoryIdsForTag}}
    />
  </div>
  */
  {
    "id": "o2rrmoHn",
    "block": "[[[8,[39,0],null,[[\"@filterTags\"],[[30,0,[\"model\",\"filter_tags\"]]]],null],[1,\"\\n\"],[10,\"h3\"],[12],[1,[28,[35,2],[\"discourse_epic_games.admin.category_matrix\"],null]],[13],[1,\"\\n\"],[10,0],[14,0,\"filter-tag-admin-pane\"],[12],[1,\"\\n  \"],[8,[39,4],null,[[\"@filterTags\",\"@setCategoryIdsForTag\"],[[30,0,[\"model\",\"filter_tags\"]],[30,0,[\"setCategoryIdsForTag\"]]]],null],[1,\"\\n\"],[13]],[],false,[\"filter-tags-editor\",\"h3\",\"i18n\",\"div\",\"category-matrix\"]]",
    "moduleName": "discourse/plugins/discourse-epic-games/discourse/templates/admin-plugins-filter-tags.hbs",
    "isStrictMode": false
  });
});