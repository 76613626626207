define("discourse/plugins/discourse-epic-games/discourse/pre-initializers/filter-category-list-by-global-filter", ["exports", "@ember/object", "@ember/owner", "discourse/lib/ajax", "discourse/lib/plugin-api", "discourse/lib/preload-store", "discourse/models/category-list", "discourse-common/lib/get-owner"], function (_exports, _object, _owner, _ajax, _pluginApi, _preloadStore, _categoryList, _getOwner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PLUGIN_ID = "discourse-epic-games";
  var _default = _exports.default = {
    name: "epic-filter-category-list-by-global-filter",
    before: "inject-discourse-objects",
    initialize() {
      (0, _pluginApi.withPluginApi)("1.3.0", api => {
        api.modifyClass("model:category", Superclass => class extends Superclass {
          get subcategories() {
            const site = (0, _owner.getOwner)(this).lookup("service:site");
            const store = (0, _owner.getOwner)(this).lookup("service:store");

            // the global filter plugin returns a filtered list of subcategories
            // so we need to override the subcategories getter to use the filtered list provided
            return this.subcategory_list?.map(c => {
              const category = store.createRecord("category", c);

              // since we're reloading the model data anyway, it doesn't hurt to update the cache
              // this also will work if lazy loading is enabled
              site.updateCategory(category);
              return category;
            });
          }
          static #_ = (() => dt7948.n(this.prototype, "subcategories", [(0, _object.computed)("subcategory_list.[]")]))();
        });
        api.modifyClassStatic("model:category-list", {
          pluginId: PLUGIN_ID,
          globalFilterListCallbacks: [],
          globalFilterQueryParam() {
            return new URLSearchParams(window.location.search).get("tag");
          },
          list(store) {
            let parentCategory = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
            const site = (0, _getOwner.getOwnerWithFallback)(this).lookup("service:site");
            const tagParam = this.globalFilterQueryParam() || site.globalFilter;

            // Since core makes an additional ajax call to /categories
            // we need to override the list function to pass a tag parameter
            // so that we serve filtered (by GFT) categories
            const getCategories = () => (0, _ajax.ajax)(`/categories.json?tag=${tagParam}`);
            return _preloadStore.default.getAndRemove("categories_list", getCategories).then(result => {
              const list = _categoryList.default.create({
                store,
                categories: this.categoriesFrom(store, result, parentCategory),
                parentCategory,
                can_create_category: result.category_list.can_create_category,
                can_create_topic: result.category_list.can_create_topic
              });

              // adds the global filter to the list object to be used if needed
              list.globalFilter = tagParam;

              // trigger any callbacks that have been registered with the update categories list
              // at the moment this is to update the categories in the sidebar without performing another API call
              this.globalFilterListCallbacks.forEach(callback => {
                callback(list);
              });
              return list;
            });
          }
        });
      });
    }
  };
});