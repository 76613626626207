define("discourse/plugins/discourse-epic-games/select-kit/addon/components/global-filter-chooser", ["exports", "@ember/object", "@ember-decorators/component", "discourse/lib/ajax", "discourse/lib/plugin-api", "select-kit/components/multi-select", "select-kit/components/select-kit", "discourse/plugins/discourse-epic-games/discourse/lib/open-composer"], function (_exports, _object, _component, _ajax, _pluginApi, _multiSelect, _selectKit, _openComposer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const GlobalFilterChooser = dt7948.c(class GlobalFilterChooser extends _multiSelect.default {
    valueProperty = "name";
    didInsertElement() {
      super.didInsertElement(...arguments);
      this.setCategoriesForFilter();
    }
    get filtersWithChildren() {
      return this.loadAdditionalFilters(this.site.global_filters);
    }
    get selectedContent() {
      if (!this.value) {
        return [];
      }
      return this.filtersWithChildren.filter(filterTag => this.value.includes(filterTag.name));
    }
    static #_ = (() => dt7948.n(this.prototype, "selectedContent", [(0, _object.computed)("value.[]", "content.[]", "filtersWithChildren.[]")]))();
    get content() {
      if (!this.value) {
        return [];
      }

      // set remaining dropdown content values
      return this.filtersWithChildren.filter(filterTag => !this.value.includes(filterTag.name));
    }
    select(value) {
      const updatedValues = [...this.value, value];
      this.updateCategoryDropdown(updatedValues);
      super.select(...arguments);
      (0, _openComposer.openComposer)({
        filter: value,
        editing: true
      });
    }
    deselect(value) {
      const updatedValues = this.value.filter(tag => tag !== value.name);
      this.updateCategoryDropdown(updatedValues);
      super.deselect(...arguments);
      (0, _openComposer.openComposer)({
        filter: value.name,
        deselect: true,
        editing: true
      });
    }
    updateCategoryDropdown(tags) {
      (0, _pluginApi.withPluginApi)("1.3.0", api => {
        (0, _ajax.ajax)(`/discourse-epic-games/filter_tags/categories_for_filter_tags.json`, {
          data: {
            tags
          }
        }).then(model => {
          api.modifySelectKit("category-chooser").replaceContent(categoryDrop => {
            if (!categoryDrop.selectKit.filter) {
              const categoriesAndSubcategories = model.categories.concat(model.subcategories);
              const filteredSubcategories = categoryDrop.content.filter(c => {
                const categoriesByName = categoriesAndSubcategories.map(item => item["name"]);
                return categoriesByName.includes(c.name || categoryDrop.allCategoriesLabel || categoryDrop.noCategoriesLabel);
              });
              return filteredSubcategories;
            }
          });
        });
      });
    }
    setCategoriesForFilter() {
      // update category dropdown with valid values
      (0, _pluginApi.withPluginApi)("1.3.0", api => {
        (0, _ajax.ajax)(`/discourse-epic-games/filter_tags/categories_for_current_filter.json`).then(model => {
          api.modifySelectKit("category-chooser").replaceContent(categoryDrop => {
            if (!categoryDrop.selectKit.filter) {
              const categoriesAndSubcategories = model.categories.concat(model.subcategories);
              const filteredSubcategories = categoryDrop.content.filter(c => {
                const categoriesByName = categoriesAndSubcategories.map(item => item["name"]);
                return categoriesByName.includes(c.name || categoryDrop.allCategoriesLabel || categoryDrop.noCategoriesLabel);
              });
              return filteredSubcategories;
            }
          });
        });
      });
    }
    loadAdditionalFilters(globalFilters) {
      let children = [];
      globalFilters.forEach(gf => {
        gf.filter_children && children.push(...Object.values(gf.filter_children));
      });
      const filters = this.siteSettings.replace_global_filter_with_children ? globalFilters.filter(f => !f.filter_children) : globalFilters;
      return [...filters, ...children];
    }
    modifyComponentForRow() {
      return "global-filter-chooser-row";
    }
  }, [(0, _component.classNames)("global-filter-chooser"), (0, _selectKit.pluginApiIdentifiers)("global-filter-chooser"), (0, _selectKit.selectKitOptions)({
    selectedChoiceComponent: "global-filter/selected-choice",
    headerComponent: "global-filter/header"
  })]);
  var _default = _exports.default = GlobalFilterChooser;
});