define("discourse/plugins/discourse-epic-games/discourse/components/global-filter/selected-choice-name", ["exports", "@glimmer/component", "discourse/lib/utilities", "discourse-common/helpers/d-icon", "@ember/component", "@ember/template-factory"], function (_exports, _component, _utilities, _dIcon, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class GlobalFilterSelectedChoiceName extends _component.default {
    get displayName() {
      if (this.args.item.alternate_name) {
        return this.args.item.alternate_name.replace(/-/g, " ");
      }
      return this.args.item.name.replace(/-/g, " ");
    }
    get icon() {
      return (0, _utilities.escapeExpression)(this.args.item.icon) || `gf-${this.args.item.name}`;
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="selected-global-filter-choice">
          {{icon this.icon}}
          {{this.displayName}}
        </div>
      
    */
    {
      "id": "xFis0qWv",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"selected-global-filter-choice\"],[12],[1,\"\\n      \"],[1,[28,[32,0],[[30,0,[\"icon\"]]],null]],[1,\"\\n      \"],[1,[30,0,[\"displayName\"]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-epic-games/discourse/components/global-filter/selected-choice-name.js",
      "scope": () => [_dIcon.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = GlobalFilterSelectedChoiceName;
});