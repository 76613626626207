define("discourse/plugins/discourse-epic-games/discourse/controllers/admin-plugins-filter-tags", ["exports", "@ember/controller", "@ember/object", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _controller, _object, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class _default extends _controller.default {
    async setCategoryIdsForTag(filterTag, cids) {
      try {
        await (0, _ajax.ajax)(`/admin/plugins/discourse-epic-games/filter_tags/${filterTag.name}/set_category_ids_for_tag.json`, {
          data: {
            category_ids: cids
          },
          type: "POST"
        });
        filterTag.set("category_ids", cids.join("|"));
      } catch (error) {
        (0, _ajaxError.popupAjaxError)(error);
      }
    }
    static #_ = (() => dt7948.n(this.prototype, "setCategoryIdsForTag", [_object.action]))();
  }
  _exports.default = _default;
});