define("discourse/plugins/discourse-epic-games/discourse/initializers/filtered-categories-for-global-filter", ["exports", "@ember/object", "@ember/service", "discourse/lib/plugin-api", "discourse/models/category-list", "discourse/plugins/discourse-epic-games/discourse/lib/constants"], function (_exports, _object, _service, _pluginApi, _categoryList, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "epic-filtered-categories-for-global-filter",
    initialize(container) {
      const epicFilteredCategories = container.lookup("service:epic-filtered-categories");
      _categoryList.default.globalFilterListCallbacks?.push(list => {
        epicFilteredCategories.updateCategories(list);
      });
      (0, _pluginApi.withPluginApi)("1.3.0", api => {
        api.modifyClass("controller:discovery/categories", {
          pluginId: _constants.PLUGIN_ID,
          queryParams: ["tag"]
        });
        api.modifyClass("route:discovery/categories", {
          pluginId: _constants.PLUGIN_ID,
          queryParams: {
            tag: {
              refreshModel: true
            }
          }
        });
        api.modifyClass("service:site", {
          pluginId: _constants.PLUGIN_ID,
          epicFilteredCategories: (0, _service.service)(),
          globalFilterObserver: (0, _object.observer)("globalFilter", function () {
            // the observer tracks modifications to site.globalFilter but can be updated in quick succession
            // loadCategories ignores the subsequent request if the globalFilter is the same
            this.epicFilteredCategories.loadCategories(this.globalFilter);
          })
        });
      });
    }
  };
});