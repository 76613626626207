define("discourse/plugins/discourse-epic-games/discourse/components/filter-tags-editor", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-common/utils/decorators", "discourse/plugins/discourse-epic-games/discourse/components/admin/filter-alternate-name", "discourse/plugins/discourse-epic-games/discourse/components/admin/filter-children-form", "discourse/plugins/discourse-epic-games/discourse/components/admin/filter-children-table", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _ajax, _ajaxError, _decorators, _filterAlternateName, _filterChildrenForm, _filterChildrenTable, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class FilterTagsEditor extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "toggleChildMenuFor", [_tracking.tracked]))();
    #toggleChildMenuFor = (() => (dt7948.i(this, "toggleChildMenuFor"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "editingChildFor", [_tracking.tracked]))();
    #editingChildFor = (() => (dt7948.i(this, "editingChildFor"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "filterChildName", [_tracking.tracked]))();
    #filterChildName = (() => (dt7948.i(this, "filterChildName"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "alternateChildTagName", [_tracking.tracked]))();
    #alternateChildTagName = (() => (dt7948.i(this, "alternateChildTagName"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "icon", [_tracking.tracked]))();
    #icon = (() => (dt7948.i(this, "icon"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "filterTags", [_tracking.tracked], function () {
      return this.args.filterTags;
    }))();
    #filterTags = (() => (dt7948.i(this, "filterTags"), void 0))();
    setCreateChild(filterTag) {
      this.toggleChildMenuFor = filterTag;
    }
    static #_7 = (() => dt7948.n(this.prototype, "setCreateChild", [_object.action]))();
    setFilterChildName(value) {
      this.filterChildName = value;
    }
    static #_8 = (() => dt7948.n(this.prototype, "setFilterChildName", [_object.action]))();
    editFilterChildForFilter(filter, childValues) {
      this.toggleChildMenuFor = filter;
      this.editingChildFor = filter;
      this.filterChildName = childValues.name;
      this.alternateChildTagName = childValues.alternate_name;
      this.icon = childValues.icon;
    }
    static #_9 = (() => dt7948.n(this.prototype, "editFilterChildForFilter", [_object.action]))();
    cancelSetFilterChildrenForFilter() {
      this.toggleChildMenuFor = null;
      this.editingChildFor = null;
      this.filterChildName = null;
      this.alternateChildTagName = null;
      this.icon = null;
    }
    static #_10 = (() => dt7948.n(this.prototype, "cancelSetFilterChildrenForFilter", [_object.action]))();
    async setAlternateNameForFilter(event) {
      try {
        const response = await (0, _ajax.ajax)(`/admin/plugins/discourse-epic-games/filter_tags/${event.target.dataset.tagName}/set_alternate_name_for_tag.json`, {
          data: {
            alternate_name: event.target.value
          },
          type: "POST"
        });
        return response;
      } catch (error) {
        (0, _ajaxError.popupAjaxError)(error);
      }
    }
    static #_11 = (() => dt7948.n(this.prototype, "setAlternateNameForFilter", [(0, _decorators.debounce)(500)]))();
    async setAlternateComposerOnlyForFilter(event) {
      try {
        const response = await (0, _ajax.ajax)(`/admin/plugins/discourse-epic-games/filter_tags/${event.target.dataset.tagName}/set_alternate_composer_only_for_tag.json`, {
          data: {
            alternate_composer_only: !!event.target.checked
          },
          type: "POST"
        });
        return response;
      } catch (error) {
        (0, _ajaxError.popupAjaxError)(error);
      }
    }
    static #_12 = (() => dt7948.n(this.prototype, "setAlternateComposerOnlyForFilter", [_object.action]))();
    async setFilterChildrenForFilter(filter) {
      try {
        const response = await (0, _ajax.ajax)(`/admin/plugins/discourse-epic-games/filter_tags/${filter}/set_filter_children_for_tag.json`, {
          data: {
            // instead of having seperate create / edit functions we can just
            // toggle this one line to either get the existing child name or
            // get the child name from the tag drop
            child_tag: this.editingChildFor ? this.filterChildName : this.filterChildName?.[0],
            alternate_child_tag_name: this.alternateChildTagName,
            icon: this.icon
          },
          type: "POST"
        });
        this.filterTags = response.filter_tags;
        this.toggleChildMenuFor = null;
        this.filterChildName = null;
        this.alternateChildTagName = null;
        this.icon = null;
      } catch (error) {
        (0, _ajaxError.popupAjaxError)(error);
      }
    }
    static #_13 = (() => dt7948.n(this.prototype, "setFilterChildrenForFilter", [_object.action]))();
    async deleteFilterChildForFilter(filter, name) {
      try {
        const response = await (0, _ajax.ajax)(`/admin/plugins/discourse-epic-games/filter_tags/${filter}/delete_filter_child_for_tag.json`, {
          data: {
            child_tag: name
          },
          type: "DELETE"
        });
        this.filterTags = response.filter_tags;
        this.toggleChildMenuFor = null;
        this.filterChildName = null;
        this.alternateChildTagName = null;
        this.icon = null;
      } catch (error) {
        (0, _ajaxError.popupAjaxError)(error);
      }
    }
    static #_14 = (() => dt7948.n(this.prototype, "deleteFilterChildForFilter", [_object.action]))();
    static #_15 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div>
          {{#each this.filterTags as |filterTag|}}
            <div class="global-filter-wrapper">
              <h2>{{filterTag.name}}</h2>
              <div class="alternate-filter-name-picker-wrapper">
                <FilterAlternateName
                  @filterTag={{filterTag}}
                  @setAlternateNameForFilter={{this.setAlternateNameForFilter}}
                  @setAlternateComposerOnlyForFilter={{this.setAlternateComposerOnlyForFilter}}
                />
                <div>
                  <FilterChildrenTable
                    @filterTag={{filterTag}}
                    @editFilterChildForFilter={{this.editFilterChildForFilter}}
                    @deleteFilterChildForFilter={{this.deleteFilterChildForFilter}}
                  />
                  <FilterChildrenForm
                    @filterTag={{filterTag}}
                    @filterChildName={{this.filterChildName}}
                    @setFilterChildName={{this.setFilterChildName}}
                    @toggleChildMenuFor={{this.toggleChildMenuFor}}
                    @editingChildFor={{this.editingChildFor}}
                    @alternateChildTagName={{this.alternateChildTagName}}
                    @icon={{this.icon}}
                    @setFilterChildrenForFilter={{this.setFilterChildrenForFilter}}
                    @cancelSetFilterChildrenForFilter={{this.cancelSetFilterChildrenForFilter}}
                    @setCreateChild={{this.setCreateChild}}
                  />
                </div>
              </div>
            </div>
            <br />
          {{/each}}
        </div>
      
    */
    {
      "id": "DlCJcB5u",
      "block": "[[[1,\"\\n    \"],[10,0],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,0,[\"filterTags\"]]],null]],null],null,[[[1,\"        \"],[10,0],[14,0,\"global-filter-wrapper\"],[12],[1,\"\\n          \"],[10,\"h2\"],[12],[1,[30,1,[\"name\"]]],[13],[1,\"\\n          \"],[10,0],[14,0,\"alternate-filter-name-picker-wrapper\"],[12],[1,\"\\n            \"],[8,[32,0],null,[[\"@filterTag\",\"@setAlternateNameForFilter\",\"@setAlternateComposerOnlyForFilter\"],[[30,1],[30,0,[\"setAlternateNameForFilter\"]],[30,0,[\"setAlternateComposerOnlyForFilter\"]]]],null],[1,\"\\n            \"],[10,0],[12],[1,\"\\n              \"],[8,[32,1],null,[[\"@filterTag\",\"@editFilterChildForFilter\",\"@deleteFilterChildForFilter\"],[[30,1],[30,0,[\"editFilterChildForFilter\"]],[30,0,[\"deleteFilterChildForFilter\"]]]],null],[1,\"\\n              \"],[8,[32,2],null,[[\"@filterTag\",\"@filterChildName\",\"@setFilterChildName\",\"@toggleChildMenuFor\",\"@editingChildFor\",\"@alternateChildTagName\",\"@icon\",\"@setFilterChildrenForFilter\",\"@cancelSetFilterChildrenForFilter\",\"@setCreateChild\"],[[30,1],[30,0,[\"filterChildName\"]],[30,0,[\"setFilterChildName\"]],[30,0,[\"toggleChildMenuFor\"]],[30,0,[\"editingChildFor\"]],[30,0,[\"alternateChildTagName\"]],[30,0,[\"icon\"]],[30,0,[\"setFilterChildrenForFilter\"]],[30,0,[\"cancelSetFilterChildrenForFilter\"]],[30,0,[\"setCreateChild\"]]]],null],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,\"br\"],[12],[13],[1,\"\\n\"]],[1]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"filterTag\"],false,[\"each\",\"-track-array\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-epic-games/discourse/components/filter-tags-editor.js",
      "scope": () => [_filterAlternateName.default, _filterChildrenTable.default, _filterChildrenForm.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = FilterTagsEditor;
});