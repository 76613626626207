define("discourse/plugins/discourse-epic-games/discourse/lib/digital-consent", ["exports", "discourse/plugins/discourse-epic-games/discourse/components/digital-consent-tooltip", "discourse/plugins/discourse-epic-games/discourse/lib/constants"], function (_exports, _digitalConsentTooltip, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = addConsentTooltip;
  _exports.hasDigitalConsentPendingGroup = hasDigitalConsentPendingGroup;
  function addConsentTooltip(currentUser, tooltip, site, selector) {
    // present tooltip if the user is in the digital_consent_pending group
    if (hasDigitalConsentPendingGroup(currentUser)) {
      // clear the active tooltip if it exists
      tooltip.close();
      const tooltipElement = getElementClosestToMiddle(_constants.EPIC_DIGITAL_CONSENT_TOOLTIP_ELEMENT_SELECTORS[selector]);
      if (!tooltipElement) {
        // Return true to stop the original action, but don't show the tooltip
        // because there's no element to attach it to
        return true;
      }
      tooltip.show(tooltipElement, {
        component: _digitalConsentTooltip.default,
        identifier: "digital-consent-tooltip",
        placement: "top-end",
        fallbackPlacements: ["bottom-end"]
      });
      return true;
    }
    // otherwise, return false and continue
    return false;
  }
  function hasDigitalConsentPendingGroup(currentUser) {
    if (!currentUser || !currentUser.groups) {
      return false;
    }
    return currentUser.groups.some(group => group.name === _constants.DIGITAL_CONSENT_PENDING_GROUP);
  }
  // since we share a action across multiple elements we can't be sure which need to find the nearest element
  // to the middle of the viewport which is visible
  function getElementClosestToMiddle(selector) {
    const elements = document.querySelectorAll(selector);
    let closestElement = null;
    let smallestDistance = Infinity;
    const viewportMiddle = (window.innerHeight || document.documentElement.clientHeight) / 2;
    for (const element of elements) {
      // Skip elements that are not visible
      if (getComputedStyle(element).display === "none") {
        continue;
      }
      const rect = element.getBoundingClientRect();
      // Check if the element is in the viewport
      if (rect.top >= 0 && rect.left >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && rect.right <= (window.innerWidth || document.documentElement.clientWidth)) {
        // Calculate the distance from the middle of the element to the viewport middle
        const elementMiddle = (rect.top + rect.bottom) / 2;
        const distanceToMiddle = Math.abs(elementMiddle - viewportMiddle);
        // Track the element with the smallest distance to the viewport middle
        if (distanceToMiddle < smallestDistance) {
          smallestDistance = distanceToMiddle;
          closestElement = element;
        }
      }
    }
    return closestElement; // Return the closest element, or null if none are visible
  }
});