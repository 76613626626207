define("discourse/plugins/discourse-epic-games/select-kit/addon/components/global-filter-chooser-row", ["exports", "@ember/object", "@ember-decorators/component", "discourse/lib/utilities", "I18n", "select-kit/components/select-kit/select-kit-row"], function (_exports, _object, _component, _utilities, _I18n, _selectKitRow) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const GlobalFilterChooserRow = dt7948.c(class GlobalFilterChooserRow extends _selectKitRow.default {
    get icons() {
      return [(0, _utilities.escapeExpression)(this.item.icon) || `gf-${this.rowName}`];
    }
    get label() {
      const label = this.rowLabel || this.getProperty(this.item, "label") || this.title || this.rowName;
      if (this.selectKit.options.allowAny && this.rowValue === this.selectKit.filter && this.getName(this.selectKit.noneItem) !== this.rowName && this.getName(this.selectKit.newItem) === this.rowName) {
        return _I18n.default.t("select_kit.create", {
          content: label
        });
      }
      return this.item.alternate_name || this.item.name?.replace(/-|_/g, " ") || "";
    }
    static #_ = (() => dt7948.n(this.prototype, "label", [(0, _object.computed)("rowLabel", "item.label", "title", "rowName")]))();
  }, [(0, _component.classNames)("global-filter-chooser-row")]);
  var _default = _exports.default = GlobalFilterChooserRow;
});