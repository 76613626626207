define("discourse/plugins/discourse-epic-games/discourse/connectors/after-breadcrumbs/topic-list-filter-wrapper", ["exports", "@glimmer/component", "discourse/plugins/discourse-epic-games/discourse/components/topic-list-filter", "@ember/component", "@ember/template-factory"], function (_exports, _component, _topicListFilter, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class TopicListFilterWrapper extends _component.default {
    static shouldRender(outletArgs, _ref) {
      let {
        siteSettings
      } = _ref;
      if (!siteSettings.epic_topic_filter_enabled) {
        return false;
      }
      if (parseInt(siteSettings.epic_display_topic_filter_on_category, 10) !== outletArgs.category?.id) {
        return false;
      }
      if (!siteSettings.epic_display_topic_filter_on_tags.split("|").includes(outletArgs.tag?.id)) {
        return false;
      }
      return true;
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="topic-list-filter-wrapper">
          <TopicListFilter
            @filterTag={{@outletArgs.tag.id}}
            @category={{@outletArgs.category.slug}}
          />
        </div>
      
    */
    {
      "id": "9k+1d2AZ",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"topic-list-filter-wrapper\"],[12],[1,\"\\n      \"],[8,[32,0],null,[[\"@filterTag\",\"@category\"],[[30,1,[\"tag\",\"id\"]],[30,1,[\"category\",\"slug\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@outletArgs\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-epic-games/discourse/connectors/after-breadcrumbs/topic-list-filter-wrapper.js",
      "scope": () => [_topicListFilter.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = TopicListFilterWrapper;
});