define("discourse/plugins/discourse-epic-games/discourse/components/global-filter/filtered-categories-chooser", ["exports", "@ember/helper", "discourse/components/plugin-outlet", "select-kit/components/category-drop", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _helper, _pluginOutlet, _categoryDrop, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const FilteredCategoriesChooser = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    {{#unless @editingCategory}}
      {{#each @categoryBreadcrumbs as |breadcrumb|}}
        {{#if breadcrumb.hasOptions}}
          <li
            class="filtered-category-drop
              {{if
                breadcrumb.isSubcategory
                'gft-subcategories-drop'
                'gft-parent-categories-drop'
              }}"
          >
            <PluginOutlet
              @name="before-filtered-category-drop"
              @outletArgs={{hash category=breadcrumb.category}}
            />
            <CategoryDrop
              @category={{breadcrumb.category}}
              @categories={{breadcrumb.options}}
              @tagId={{@tagId}}
              @editingCategory={{@editingCategory}}
              @editingCategoryTab={{@editingCategoryTab}}
              @options={{hash
                parentCategory=breadcrumb.parentCategory
                subCategory=breadcrumb.isSubcategory
                noSubcategories=breadcrumb.noSubcategories
                autoFilterable=true
              }}
            />
          </li>
        {{/if}}
      {{/each}}
    {{/unless}}
  
  */
  {
    "id": "9MqczhEG",
    "block": "[[[1,\"\\n\"],[41,[51,[30,1]],[[[42,[28,[31,2],[[28,[31,2],[[30,2]],null]],null],null,[[[41,[30,3,[\"hasOptions\"]],[[[1,\"        \"],[10,\"li\"],[15,0,[29,[\"filtered-category-drop\\n            \",[52,[30,3,[\"isSubcategory\"]],\"gft-subcategories-drop\",\"gft-parent-categories-drop\"]]]],[12],[1,\"\\n          \"],[8,[32,0],null,[[\"@name\",\"@outletArgs\"],[\"before-filtered-category-drop\",[28,[32,1],null,[[\"category\"],[[30,3,[\"category\"]]]]]]],null],[1,\"\\n          \"],[8,[32,2],null,[[\"@category\",\"@categories\",\"@tagId\",\"@editingCategory\",\"@editingCategoryTab\",\"@options\"],[[30,3,[\"category\"]],[30,3,[\"options\"]],[30,4],[30,1],[30,5],[28,[32,1],null,[[\"parentCategory\",\"subCategory\",\"noSubcategories\",\"autoFilterable\"],[[30,3,[\"parentCategory\"]],[30,3,[\"isSubcategory\"]],[30,3,[\"noSubcategories\"]],true]]]]],null],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null]],[3]],null]],[]],null]],[\"@editingCategory\",\"@categoryBreadcrumbs\",\"breadcrumb\",\"@tagId\",\"@editingCategoryTab\"],false,[\"unless\",\"each\",\"-track-array\",\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-epic-games/discourse/components/global-filter/filtered-categories-chooser.js",
    "scope": () => [_pluginOutlet.default, _helper.hash, _categoryDrop.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "filtered-categories-chooser:FilteredCategoriesChooser"));
  var _default = _exports.default = FilteredCategoriesChooser;
});