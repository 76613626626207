define("discourse/plugins/discourse-epic-games/discourse/routes/admin-plugins-filter-tags", ["exports", "@ember/object", "discourse/lib/ajax", "discourse/routes/discourse"], function (_exports, _object, _ajax, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsFilterTags extends _discourse.default {
    model() {
      return (0, _ajax.ajax)("/admin/plugins/discourse-epic-games/filter_tags.json").then(model => {
        model.filter_tags = model.filter_tags.map(filter_tag => _object.default.create(filter_tag));
        return model;
      });
    }
  }
  _exports.default = AdminPluginsFilterTags;
});