define("discourse/plugins/discourse-epic-games/discourse/connectors/inline-extra-nav-item/mobile-filtered-tags-chooser", ["exports", "@glimmer/component", "@ember/helper", "@ember/service", "discourse/plugins/discourse-epic-games/discourse/components/global-filter/filtered-tags-chooser", "@ember/component", "@ember/template-factory"], function (_exports, _component, _helper, _service, _filteredTagsChooser, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class MobileFilteredTagsChooser extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.site.mobileView}}
          <FilteredTagsChooser
            @currentCategory={{@category}}
            @options={{hash categoryId=@category.id}}
          />
        {{/if}}
      
    */
    {
      "id": "QY5UYiSU",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"site\",\"mobileView\"]],[[[1,\"      \"],[8,[32,0],null,[[\"@currentCategory\",\"@options\"],[[30,1],[28,[32,1],null,[[\"categoryId\"],[[30,1,[\"id\"]]]]]]],null],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@category\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-epic-games/discourse/connectors/inline-extra-nav-item/mobile-filtered-tags-chooser.js",
      "scope": () => [_filteredTagsChooser.default, _helper.hash],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = MobileFilteredTagsChooser;
});