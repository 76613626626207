define("discourse/plugins/discourse-epic-games/discourse/connectors/category-list-after-topics-section/replies-section", ["exports", "discourse/helpers/number", "discourse-common/helpers/d-icon", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _number, _dIcon, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const RepliesSection = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <td class="replies">
      <div title="post count">
        {{dIcon "reply"}}
        {{number @outletArgs.category.post_count}}
      </div>
    </td>
  
  */
  {
    "id": "9J/GodJo",
    "block": "[[[1,\"\\n  \"],[10,\"td\"],[14,0,\"replies\"],[12],[1,\"\\n    \"],[10,0],[14,\"title\",\"post count\"],[12],[1,\"\\n      \"],[1,[28,[32,0],[\"reply\"],null]],[1,\"\\n      \"],[1,[28,[32,1],[[30,1,[\"category\",\"post_count\"]]],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@outletArgs\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-epic-games/discourse/connectors/category-list-after-topics-section/replies-section.js",
    "scope": () => [_dIcon.default, _number.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "replies-section:RepliesSection"));
  var _default = _exports.default = RepliesSection;
});