define("discourse/plugins/discourse-epic-games/discourse/initializers/replace-icons", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "epic-replace-icons",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.36", api => {
        api.replaceIcon("bars", "eg-burger");
        api.replaceIcon("magnifying-glass", "ue-search");
        api.replaceIcon("house", "ue-logo-new");
        api.replaceIcon("reply", "ue-arrow-turn-up-left");
        api.replaceIcon("sliders", "ue-sliders");
        api.replaceIcon("globe", "ue-globe");
        api.replaceIcon("far-square-check", "ue-check");
        api.replaceIcon("plus", "ue-plus");
        api.replaceIcon("d-tracking", "ue-notifications");
        api.replaceIcon("d-watching", "ue-notification-important");
        api.replaceIcon("d-regular", "ue-icon-bell");
        api.replaceIcon("d-muted", "ue-icon-bell-slash");
        api.replaceIcon("bell", "ue-icon-bell");
        api.replaceIcon("comment", "ue-talk-bubble-dots");
        api.replaceIcon("circle-question", "ue-question-circle");
        api.replaceIcon("circle-exclamation", "ue-exclamation-circle");
        api.replaceIcon("chevron-down", "ue-caret-down");
        api.replaceIcon("chevron-up", "ue-caret-up");

        /* change all link icons */
        api.replaceIcon("link", "ue-link");
        api.replaceIcon("d-post-share", "ue-link");
        api.replaceIcon("d-topic-share", "ue-link");
        api.replaceIcon("ellipsis", "ue-ellipsis-horizontal");

        /* -- change all heart/like icons -- */
        api.replaceIcon("d-unliked", "ue-icon-unliked");
        api.replaceIcon("d-liked", "ue-icon-liked");
        api.replaceIcon("notification.liked", "ue-icon-unliked");
        api.replaceIcon("heart", "ue-icon-unliked");

        /* -- bookmarks posts -- */
        api.replaceIcon("discourse-bookmark", "ue-bookmark");
        api.replaceIcon("bookmark", "ue-bookmark");
        api.replaceIcon("discourse-bookmark-clock", "ue-bookmark-filled");

        /* -- composer toolbar -- */
        api.replaceIcon("angles-left", "ue-arrow-left-to-line");
        api.replaceIcon("discourse-expand", "ue-arrow-up-left-arrow-down-right");
        api.replaceIcon("bold", "ue-bold");
        api.replaceIcon("italic", "ue-format-italic");
        api.replaceIcon("list-ul", "ue-format-list-bulleted");
        api.replaceIcon("list-ol", "ue-format-list-numbered");
        api.replaceIcon("quote-right", "ue-format-quote");
        api.replaceIcon("upload", "ue-insert-photo");
        api.replaceIcon("flag", "ue-flag");
        api.replaceIcon("envelope", "ue-envelope");
        api.replaceIcon("notification.private_message", "ue-envelope");
        api.replaceIcon("pencil", "ue-pencil");
        api.replaceIcon("circle-info", "ue-info-circle");
        api.replaceIcon("certificate", "ue-checkmark-seal");
        api.replaceIcon("notification.granted_badge", "ue-checkmark-seal");
        api.replaceIcon("wrench", "ue-wrench");
        api.replaceIcon("user", "ue-user");

        /* -- Topic States -- */
        api.replaceIcon("folder", "ue-archive");
        api.replaceIcon("far-eye-slash", "ue-eye-slash");
        api.replaceIcon("thumbtack", "ue-pin");
        api.replaceIcon("pinned.enabled", "ue-pin");
        api.replaceIcon("pinned_globally.enabled", "ue-pin");
        api.replaceIcon("pinned.disabled", "ue-pin-slash");
        api.replaceIcon("pinned_globally.disabled", "ue-pin-slash");
        api.replaceIcon("thumbtack unpinned", "ue-pin-slash");

        /* -- Post Title Icons -- */
        api.replaceIcon("lock", "ue-lock");
        api.replaceIcon("unlock", "ue-unlock");
        api.replaceIcon("square-check", "ue-check-filled");
      });
    }
  };
});