define("discourse/plugins/discourse-epic-games/discourse/connectors/user-profile-above-collapsed-info/custom-profile-about", ["exports", "@glimmer/component", "@ember/service", "truth-helpers", "discourse-common/helpers/i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _truthHelpers, _i18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CustomProfileAbout extends _component.default {
    static shouldRender(_, _ref) {
      let {
        siteSettings
      } = _ref;
      return siteSettings.epic_enable_custom_profile_about;
    }
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <ul class="custom-user-nav">
          {{! don't show about for system users }}
          {{#if (gt @outletArgs.model.id 0)}}
            <li>
              <a
                href="{{this.siteSettings.epic_dev_community_url}}/api/user_profiles/tab-redirect/about?discourse_user_id={{@outletArgs.model.id}}"
              >
                {{i18n "discourse_epic_games.profile_toggle.about"}}
              </a>
            </li>
          {{/if}}
          <li>
            <a href="/u/{{@outletArgs.model.username}}/summary" class="active">
              {{i18n "discourse_epic_games.profile_toggle.interactions"}}
            </a>
          </li>
        </ul>
      
    */
    {
      "id": "nknXQ8gE",
      "block": "[[[1,\"\\n    \"],[10,\"ul\"],[14,0,\"custom-user-nav\"],[12],[1,\"\\n\"],[41,[28,[32,0],[[30,1,[\"model\",\"id\"]],0],null],[[[1,\"        \"],[10,\"li\"],[12],[1,\"\\n          \"],[10,3],[15,6,[29,[[30,0,[\"siteSettings\",\"epic_dev_community_url\"]],\"/api/user_profiles/tab-redirect/about?discourse_user_id=\",[30,1,[\"model\",\"id\"]]]]],[12],[1,\"\\n            \"],[1,[28,[32,1],[\"discourse_epic_games.profile_toggle.about\"],null]],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[10,\"li\"],[12],[1,\"\\n        \"],[10,3],[15,6,[29,[\"/u/\",[30,1,[\"model\",\"username\"]],\"/summary\"]]],[14,0,\"active\"],[12],[1,\"\\n          \"],[1,[28,[32,1],[\"discourse_epic_games.profile_toggle.interactions\"],null]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@outletArgs\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-epic-games/discourse/connectors/user-profile-above-collapsed-info/custom-profile-about.js",
      "scope": () => [_truthHelpers.gt, _i18n.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = CustomProfileAbout;
});