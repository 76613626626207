define("discourse/plugins/discourse-epic-games/discourse/components/digital-consent-tooltip", ["exports", "@glimmer/component", "@ember/service", "@ember/template", "discourse-common/helpers/d-icon", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _template, _dIcon, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class DigitalConsentTooltip extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="consent-tooltip">
          <div class="consent-tooltip__icon">
            {{icon "ue-exclamation-triangle"}}
          </div>
          <div class="consent-tooltip__content">
            <div class="consent-tooltip__title">
              {{i18n "discourse_epic_games.digital_consent_toast.title"}}
            </div>
            <div class="consent-tooltip__message">
              {{htmlSafe
                (i18n
                  "discourse_epic_games.digital_consent_toast.message"
                  digital_consent_url=this.siteSettings.epic_external_digital_consent_url
                )
              }}
            </div>
          </div>
        </div>
      
    */
    {
      "id": "Jmk/msds",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"consent-tooltip\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"consent-tooltip__icon\"],[12],[1,\"\\n        \"],[1,[28,[32,0],[\"ue-exclamation-triangle\"],null]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"consent-tooltip__content\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"consent-tooltip__title\"],[12],[1,\"\\n          \"],[1,[28,[32,1],[\"discourse_epic_games.digital_consent_toast.title\"],null]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"consent-tooltip__message\"],[12],[1,\"\\n          \"],[1,[28,[32,2],[[28,[32,1],[\"discourse_epic_games.digital_consent_toast.message\"],[[\"digital_consent_url\"],[[30,0,[\"siteSettings\",\"epic_external_digital_consent_url\"]]]]]],null]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-epic-games/discourse/components/digital-consent-tooltip.js",
      "scope": () => [_dIcon.default, _discourseI18n.i18n, _template.htmlSafe],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = DigitalConsentTooltip;
});