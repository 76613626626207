define("discourse/plugins/discourse-epic-games/discourse/components/global-filter/selected-choice", ["exports", "@ember/component", "select-kit/components/selected-choice", "@ember/template-factory"], function (_exports, _component, _selectedChoice, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <button
    aria-label={{i18n "select_kit.delete_item" name=this.itemName}}
    id="{{this.id}}-choice"
    data-value={{this.itemValue}}
    data-name={{this.itemName}}
    type="button"
    {{on "click" (fn this.selectKit.deselect this.item)}}
    class="btn btn-default selected-choice {{this.extraClass}}"
  >
    {{d-icon "times"}}
    {{#if (has-block)}}
      {{yield}}
    {{else}}
      <span class="d-button-label">
        <GlobalFilter::SelectedChoiceName @item={{this.item}} />
      </span>
    {{/if}}
  </button>
  */
  {
    "id": "8LuIPOdK",
    "block": "[[[11,\"button\"],[16,\"aria-label\",[28,[37,1],[\"select_kit.delete_item\"],[[\"name\"],[[30,0,[\"itemName\"]]]]]],[16,1,[29,[[30,0,[\"id\"]],\"-choice\"]]],[16,\"data-value\",[30,0,[\"itemValue\"]]],[16,\"data-name\",[30,0,[\"itemName\"]]],[16,0,[29,[\"btn btn-default selected-choice \",[30,0,[\"extraClass\"]]]]],[24,4,\"button\"],[4,[38,2],[\"click\",[28,[37,3],[[30,0,[\"selectKit\",\"deselect\"]],[30,0,[\"item\"]]],null]],null],[12],[1,\"\\n  \"],[1,[28,[35,4],[\"times\"],null]],[1,\"\\n\"],[41,[48,[30,1]],[[[1,\"    \"],[18,1,null],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,1],[14,0,\"d-button-label\"],[12],[1,\"\\n      \"],[8,[39,9],null,[[\"@item\"],[[30,0,[\"item\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]],[13]],[\"&default\"],false,[\"button\",\"i18n\",\"on\",\"fn\",\"d-icon\",\"if\",\"has-block\",\"yield\",\"span\",\"global-filter/selected-choice-name\"]]",
    "moduleName": "discourse/plugins/discourse-epic-games/discourse/components/global-filter/selected-choice.hbs",
    "isStrictMode": false
  });
  class SelectedChoice extends _selectedChoice.default {}
  _exports.default = SelectedChoice;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, SelectedChoice);
});