define("discourse/plugins/discourse-epic-games/discourse/lib/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.SEARCH_ACTIVE = _exports.REALITY_CAPTURE_OR_FORTNITE_FILTERS = _exports.PLUGIN_ID = _exports.FIXED_STATUS = _exports.EPIC_SIDEBAR_LOCALE_MAP = _exports.EPIC_HEADER_SIGN_IN_URL = _exports.EPIC_DIGITAL_CONSENT_TOOLTIP_ELEMENT_SELECTORS = _exports.EPIC_BADGES = _exports.EDC_BORDER_COLOR = _exports.DSA_FLAG_TYPES = _exports.DRAWER_TOGGLE = _exports.DISCOURSE_SYSTEM_USERS = _exports.DISCOURSE_LOCALE_MAP = _exports.DIGITAL_CONSENT_PENDING_GROUP = _exports.BUG_REPORT_TOPIC_STATUSES = void 0;
  const PLUGIN_ID = _exports.PLUGIN_ID = "discourse-epic-games";
  const REALITY_CAPTURE_OR_FORTNITE_FILTERS = _exports.REALITY_CAPTURE_OR_FORTNITE_FILTERS = ["fortnite", "fortnite-creative", "unreal-editor-for-fortnite", "capturing-reality", "reality-capture", "reality-scan"];
  const DISCOURSE_SYSTEM_USERS = _exports.DISCOURSE_SYSTEM_USERS = ["system", "discobot"];
  const DIGITAL_CONSENT_PENDING_GROUP = _exports.DIGITAL_CONSENT_PENDING_GROUP = "consent_to_communicate_pending"; // Update the constant in plugin.rb as well
  const DSA_FLAG_TYPES = _exports.DSA_FLAG_TYPES = ["spam", "inappropriate", "illegal"];
  const FIXED_STATUS = _exports.FIXED_STATUS = "fixed";
  const SEARCH_ACTIVE = _exports.SEARCH_ACTIVE = "epic-wf-nav-search-activated";
  const DRAWER_TOGGLE = _exports.DRAWER_TOGGLE = "epic-wf-drawer-on-toggle";
  const EDC_BORDER_COLOR = _exports.EDC_BORDER_COLOR = "rgb(34,179,255)";
  const EPIC_HEADER_SIGN_IN_URL = _exports.EPIC_HEADER_SIGN_IN_URL = "/community/api/user_identity/login/start";
  const BUG_REPORT_TOPIC_STATUSES = _exports.BUG_REPORT_TOPIC_STATUSES = {
    open: {
      class: "bug-report-status-open",
      status: "discourse_epic_games.bug_report_status.open.status",
      title: "discourse_epic_games.bug_report_status.open.title",
      message: "discourse_epic_games.bug_report_status.open.message",
      description: "discourse_epic_games.bug_report_status.description"
    },
    closed: {
      class: "bug-report-status-closed",
      status: "discourse_epic_games.bug_report_status.closed.status",
      title: "discourse_epic_games.bug_report_status.closed.title",
      message: "discourse_epic_games.bug_report_status.closed.message",
      description: "discourse_epic_games.bug_report_status.description"
    },
    fixed: {
      class: "bug-report-status-fixed",
      status: "discourse_epic_games.bug_report_status.fixed.status",
      title: "discourse_epic_games.bug_report_status.fixed.title",
      message: "discourse_epic_games.bug_report_status.fixed.message",
      description: "discourse_epic_games.bug_report_status.description",
      created_at: "discourse_epic_games.bug_report_status.fixed.created_at",
      component: "discourse_epic_games.bug_report_status.fixed.component",
      affects_versions: "discourse_epic_games.bug_report_status.fixed.affects_versions",
      target_fix: "discourse_epic_games.bug_report_status.fixed.target_fix",
      resolved: "discourse_epic_games.bug_report_status.fixed.resolved",
      updated: "discourse_epic_games.bug_report_status.fixed.updated",
      fix_commit: "discourse_epic_games.bug_report_status.fixed.fix_commit"
    }
  };
  const EPIC_BADGES = _exports.EPIC_BADGES = [{
    id: 0,
    name: "Community Hero",
    icon: "badge-hero"
  }, {
    id: 1,
    name: "Staff",
    icon: "badge-staff"
  }, {
    id: 2,
    name: "Contributor"
  }, {
    id: 3,
    name: "User Group Leader"
  }, {
    id: 4,
    name: "UOL Veteran"
  }, {
    id: 5,
    name: "Forum Veteran"
  }, {
    id: 6,
    name: "Q&A Sage"
  }, {
    id: 7,
    name: "Marketplace Creator"
  }, {
    id: 8,
    name: "Engine Contributor"
  }, {
    id: 9,
    name: "Rocket Beta Participant"
  }, {
    id: 10,
    name: "Mega Grant Recipient"
  }, {
    id: 11,
    name: "Authorized Instructor"
  }, {
    id: 12,
    name: "Snippet Machine"
  }, {
    id: 13,
    name: "Tutorial Professor"
  }, {
    id: 14,
    name: "Unreal Fellow"
  }, {
    id: 15,
    name: "Training Partner"
  }, {
    id: 16,
    name: "Academic Partner"
  }];
  const DISCOURSE_LOCALE_MAP = _exports.DISCOURSE_LOCALE_MAP = {
    "en-US": "en",
    "es-ES": "es",
    "es-MX": "es",
    "pt-BR": "pt_BR",
    "zh-CN": "zh_CN",
    tr: "tr_TR",
    pl: "pl_PL"
  };
  const EPIC_SIDEBAR_LOCALE_MAP = _exports.EPIC_SIDEBAR_LOCALE_MAP = {
    fr: "fr-fr",
    "pt-BR": "pt-br",
    ko: "ko-kr",
    ja: "ja-jp",
    de: "de-de",
    it: "it-it",
    pl: "pl-pl",
    ru: "ru-ru",
    tr: "tr-tr",
    th: "th-th",
    "zn-CN": "zh-cn"
  };
  const EPIC_DIGITAL_CONSENT_TOOLTIP_ELEMENT_SELECTORS = _exports.EPIC_DIGITAL_CONSENT_TOOLTIP_ELEMENT_SELECTORS = {
    reply_to_post: "button.create",
    bookmark_menu: ".bookmark-menu-trigger",
    invite_topic: "#topic-footer-button-share-and-invite",
    invite_profile: ".invite-button",
    like: ".post-controls button.like",
    vote: ".voting-wrapper .nonvote",
    private_message: ".compose-pm",
    featured_topic: ".feature-topic-on-profile-btn",
    quote: ".quote-button .insert-quote",
    new_topic_dropdown: ".epic-new-topic-button",
    create_flag: ".flag-modal__create-flag"
  };
});