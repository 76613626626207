define("discourse/plugins/discourse-epic-games/discourse/connectors/after-title-and-category/filtered-tags-chooser", ["exports", "@ember/helper", "discourse/plugins/discourse-epic-games/discourse/components/global-filter/filtered-tags-composer-chooser", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _helper, _filteredTagsComposerChooser, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const FilteredTagsChooser = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <FilteredTagsComposerChooser
      @tagValidation={{@outletArgs.tagValidation}}
      @canEditTags={{@outletArgs.canEditTags}}
      @value={{@outletArgs.model.tags}}
      @onChange={{fn (mut @outletArgs.model.tags)}}
      @options={{hash
        disabled=@outletArgs.disabled
        categoryId=@outletArgs.model.categoryId
        minimum=@outletArgs.model.minimumRequiredTags
      }}
    />
  
  */
  {
    "id": "4UkDXTJO",
    "block": "[[[1,\"\\n  \"],[8,[32,0],null,[[\"@tagValidation\",\"@canEditTags\",\"@value\",\"@onChange\",\"@options\"],[[30,1,[\"tagValidation\"]],[30,1,[\"canEditTags\"]],[30,1,[\"model\",\"tags\"]],[28,[32,1],[[28,[31,0],[[30,1,[\"model\",\"tags\"]]],null]],null],[28,[32,2],null,[[\"disabled\",\"categoryId\",\"minimum\"],[[30,1,[\"disabled\"]],[30,1,[\"model\",\"categoryId\"]],[30,1,[\"model\",\"minimumRequiredTags\"]]]]]]],null],[1,\"\\n\"]],[\"@outletArgs\"],false,[\"mut\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-epic-games/discourse/connectors/after-title-and-category/filtered-tags-chooser.js",
    "scope": () => [_filteredTagsComposerChooser.default, _helper.fn, _helper.hash],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "filtered-tags-chooser:FilteredTagsChooser"));
  var _default = _exports.default = FilteredTagsChooser;
});